<template>
  <account />

</template>

<script>
import Account from './Account.vue'

export default {
  components: { Account },

}
</script>

<style>

</style>
