<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label=" Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="accountForm.name"
            autofocus
            trim
            placeholder="Name"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="accountForm.email"
            autofocus
            trim
            placeholder="email"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="password"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="accountForm.password"
            autofocus
            trim
            placeholder="password"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mt-50"
      >
        <b-button
          v-if="!isLoading"
          variant="primary"
          class="mr-1"
          @click="addUser()"
        >
          Save Changes
        </b-button>
        <b-button
          v-if="isLoading"
          variant="primary"
          disabled
          class="mr-1"
        >
          <b-spinner
            small
            type="grow"
          />
          Loading...
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'

export default {
  data() {
    return {
      isLoading: false,
    }
  },
  setup() {
    const accountForm = ref({
      name: '',
      email: '',
      password: '',
    })
    return {
      accountForm,
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    getUser() {
      axios.get('profile').then(res => {
        if (res.status === 200) {
          this.accountForm = res.data.data
        }
      })
    },
    addUser() {
      try {
        this.isLoading = true
        const formData = new FormData()
        formData.append('name', this.accountForm.name)
        formData.append('email', this.accountForm.email)
        if (this.accountForm.password) {
          formData.append('password', this.accountForm.password)
        }
        axios.post('update_profile', formData).then(res => {
          if (res.status === 200) {
            store.commit('login/SET_USER_INFO', res.data.data)
            this.$toasted.show('Updated Successfully', {
              position: 'bottom-center',
              duration: 3000,

            })
            this.isLoading = false

            this.getUser()
          }
        })
      } catch (e) {
        // console.log(e)
      }
    },
  },
}
</script>

<style>

</style>
